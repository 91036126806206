import React, {useEffect} from "react";
import {InputText} from "../../components/inputtext";
import {Dropdown} from "../../components/dropdown";
import {EtapaDropdown} from "../../components/autocomplete/EtapaDropdown";
import {Calendar} from "../../components/calendar";
import {ClienteAutoComplete} from "../../components/autocomplete/ClienteAutoComplete";
import {SelectButton} from "../../components/selectbutton";
import {EquipamentoAutoComplete} from "../../components/autocomplete/equipamentoAutoComplete";
import {UsuarioAutoComplete} from "../../components/autocomplete/usuarioAutoComplete";
import {filtrosNumeros, optionsFilterStatusSuporte} from "../../service/ordemServicoService";
import {CanceladaSelectButton} from "../../components/selectbutton/canceladaSelectButton";
import {VendedorAutoComplete} from "../../components/autocomplete/VendedorAutoComplete";
import {useQueryParams} from "../../utils/QueryParamContext";
import {QueryParamsKeys} from "../../utils/defaultQueryParams";
import {useAuth} from "../../context/AuthContext";
import {FaixaSLASelectButton} from "../../components/selectbutton/faixaSlaSelectButton";

export const optionsFiltrosPecas = [
	{label: "Todos", value: null},
	{label: "Sim", value: "true"},
	{label: "Não", value: "false"}
];

export const filtrosData = [
	{label: "de Início", value: "INICIO"},
	{label: "de Previsão de Atendimento", value: "PREVISAO"},
	{label: "de Fim", value: "FIM"}
];

export const filtrosDataLaboratorio = [
	{label: "de Início", value: "INICIO"},
	{label: "de Fim", value: "FIM"}
];

export function FiltrosOrdemServicoSuporte() {

	const {roles, usuario} = useAuth();
	const {params, handleChange} = useQueryParams(QueryParamsKeys.Suporte);

	useEffect(() => {
		if (!roles.OSSL) {
			handleChange({name: "responsavel", value: usuario});
		}
	}, [roles, usuario]);

	return (
		<>
			<InputText label="Número" name="numeros" col={2} value={params.numeros} onChange={handleChange}/>
			<Dropdown col={2} options={filtrosNumeros} label="&nbsp;" name="modoNumeros" onChange={handleChange} value={params.modoNumeros}/>
			<EtapaDropdown fluxo="Suporte" col={2} name="etapa" value={params.etapa} onChange={handleChange}/>
			<Calendar disabled={params.equipamento?.id} label="Período" col={1} name="inicio" value={params.inicio} onChange={handleChange}/>
			<Calendar disabled={params.equipamento?.id} label="&nbsp;" col={1} name="fim" value={params.fim} onChange={handleChange}/>
			<Dropdown col={2} label="&nbsp;" name="modoData" options={filtrosData} onChange={handleChange} value={params.modoData}/>
			<CanceladaSelectButton value={params.cancelada} onChange={handleChange}/>
			<ClienteAutoComplete forceSelection={false} name="cliente" col={4} value={params.cliente} onChange={handleChange}/>
			<EquipamentoAutoComplete forceSelection={false} col={4} name="equipamento" value={params.equipamento} onChange={handleChange}/>
			<UsuarioAutoComplete col={4} disabled={!roles.OSSL} value={params.responsavel} name="responsavel" onChange={handleChange} label="Responsável"/>
			<SelectButton options={optionsFilterStatusSuporte} col={6} name="status" value={params.status} onChange={handleChange} label="Status" className="filter-status-suporte"/>
			<VendedorAutoComplete col={3} label="Consultor de Vendas" name="gerenteComercial" value={params.gerenteComercial} onChange={handleChange}/>
			<FaixaSLASelectButton col={3} name="faixaSLA" value={params.faixaSLA} onChange={handleChange}/>
		</>
	);

}
