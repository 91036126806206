import React from "react";
import moment from "moment";
import {formatMinutes} from "../../utils/numberFormatter";
import {useQuery} from "@tanstack/react-query";
import {ansService} from "../../service/ansService";

export function CardSLA({ordemServico}) {
	const momentNow = moment(ordemServico.fim);
	const momentFim = moment(ordemServico.previsaoFim || ordemServico.previsaoFimSuporte);
	const momentInicio = moment(ordemServico.inicio);
	const minutosTotais = momentInicio.diff(momentFim, "minutes");
	const minutosCorridos = momentInicio.diff(momentNow, "minutes");
	const ratio = Math.abs(minutosCorridos) / Math.abs(minutosTotais);
	const query = useQuery({
		queryKey: ["XX-SETUP_SLA"],
		queryFn: () => ansService.configuracoes(),
		staleTime: 60000
	});
	let bgColor;
	if (query.isFetched) {
		if (ratio <= query.data.faixaVerde / 100) {
			bgColor = "green";
		} else if (ratio <= query.data.faixaAmarela / 100) {
			bgColor = "yellow";
		} else if (ratio <= query.data.faixaVermelha / 100) {
			bgColor = "red";
		} else {
			bgColor = "black";
		}
	}
	if (ordemServico.equipamentoBackupDataEnvio) {
		if (ordemServico.equipamentoBackupDataRetorno) {
			return (
				<div className="card-sla purple">
					<i className="fas fa-computer"/>
					Backup utilizado e devolvido
				</div>
			);
		}
		return (
			<div className="card-sla blue">
				<i className="fas fa-computer"/>
				Backup enviado em {moment(ordemServico.equipamentoBackupDataEnvio).format("DD/MM/YYYY")}
			</div>
		);
	}
	const prazoRestante = momentFim.diff(momentNow, "minutes");
	switch (ordemServico.status) {
		case "TRANSFERENCIA":
			return (
				<div className={`card-sla`}>
					<i className="fas fa-pause"/>
					<span style={{display: "inline-block", margin: "0 1em"}}>Em transferência</span>
				</div>
			);
		case "SUSPENSA":
			return (
				<div className={`card-sla`}>
					<i className="fas fa-pause"/>
					<span style={{display: "inline-block", margin: "0 1em"}}>Aguardando retorno</span>
				</div>
			);
		default:
			return (
				<div className={`card-sla ${bgColor}`}>
					<i className="fas fa-calendar-xmark"/>
					{momentFim.format("DD/MM/YY HH:mm")} {prazoRestante > 0 ? `(${formatMinutes(prazoRestante)})` : ""} |
					<i className="fas fa-clock"/>
					{formatMinutes(momentNow.diff(momentInicio, "minutes"))} ({Math.round(ratio * 100)}%)
				</div>
			);
	}
}
