import React, {useEffect, useState} from "react";
import {Panel} from "primereact/panel";
import {PanelContent, PanelFooter} from "../../components/panel";
import {Button} from "../../components/button";
import {useBreakpoint} from "../../context/BreakpointContext";
import {Dropdown} from "../../components/dropdown";
import {InputText} from "../../components/inputtext";
import {UsuarioAutoComplete} from "../../components/autocomplete/usuarioAutoComplete";
import {buildValidator, isEntityRequired} from "../../utils/fieldValidator";
import {ordemServicoEtapaService} from "../../service/ordemServicoEtapaService";
import {useDialog} from "../../utils/dialogContext";
import {InformationDialog} from "../../components/dialog/InformationDialog";
import {useAuth} from "../../context/AuthContext";
import {ordemServicoService, statusConsumo} from "../../service/ordemServicoService";
import {OrdemServicoFluxoDropdown} from "../../components/dropdown/ordemServicoFluxoDropdown";
import {If} from "../../components/conditional/If";
import {EtapaDropdown} from "../../components/autocomplete/EtapaDropdown";
import {ConfirmacaoEstoquePecaCampo} from "../__Commons-OrdemServico/ConfirmacaoEstoquePecaCampo";

export function EditarAcompanhamento({compras, ordemServico, setOrdemServico, acompanhamento, setAcompanhamento, onSalvar, onVoltar, multiple, required}) {

	const {showDialog} = useDialog();
	const {breakpoint} = useBreakpoint();
	const isLargeDevice = breakpoint === "lg" || breakpoint === "md";
	const {usuario} = useAuth();
	const [optionsEtapas, setOptionsEtapas] = useState([]);
	const [loading, setLoading] = useState(false);
	const [messages, setMessages] = useState(buildValidator());

	useEffect(() => {
		const acompanhamento = ordemServicoService.criarAcompanhamento();
		const optionsEtapas = ordemServico.etapas.filter(e => ordemServico.etapa.proximas.some(p => e.sequencia === p)).map(e => {
			if (statusConsumo.includes(e.status)) {
				return {
					label: <><i className="fa-solid fa-database"/> {e.descricao}</>,
					value: e
				};
			}
			return {label: e.descricao, value: e};
		}) || [];
		if (compras) {
			acompanhamento.etapa = optionsEtapas.filter(e => e.value.status === "COMPRAS")[0].value;
		} else {
			if (ordemServico.etapa.proximaPadrao) {
				const proximaOption = optionsEtapas.filter(e => e.value.sequencia === ordemServico.etapa.proximaPadrao);
				if (proximaOption.length) {
					acompanhamento.etapa = proximaOption[0].value;
				}
			}
		}
		if (optionsEtapas.length === 1) {
			acompanhamento.etapa = optionsEtapas[0].value;
		}
		setOptionsEtapas(optionsEtapas);
		setAcompanhamento(prevAcompanhamento => ({...prevAcompanhamento, ...acompanhamento}));
	}, [ordemServico]);

	function doSalvar(ordemServico) {
		setLoading(true);
		acompanhamento.atribuinte = usuario;
		if (onSalvar) {
			onSalvar(acompanhamento, ordemServico);
		}
	}

	function handleSalvar() {
		let messages = ordemServicoEtapaService.validarAcompanhamento(acompanhamento);
		if (required && (acompanhamento.etapa?.status !== "SUSPENSA" && !acompanhamento.responsavel?.id)) {
			messages.responsavel = "Campo obrigatório";
		}
		if (messages.isEmpty()) {
			if (acompanhamento.etapa?.operacaoTransferencia && acompanhamento._fluxoTransferencia && acompanhamento._etapaTransferencia) {
				acompanhamento.etapa = acompanhamento._etapaTransferencia;
				acompanhamento.operacao = acompanhamento._fluxoTransferencia.operacao;
				ordemServico.fluxo = `${acompanhamento._fluxoTransferencia.descricao} (v${acompanhamento._fluxoTransferencia.versao})`;
				ordemServico.etapas = acompanhamento._fluxoTransferencia.etapas;
				ordemServico.etapa = acompanhamento._etapaTransferencia;
				ordemServico.operacao = acompanhamento._fluxoTransferencia.operacao;
				switch (ordemServico.operacao) {
					case "CAMPO":
						ordemServico.tipo = "CORRETIVA";
						break;
					case "SUPORTE":
						ordemServico.tipo = "SUPORTE";
						break;
					case "LABORATORIO":
						ordemServico.tipo = "CORRETIVA";
						break;
				}
				ordemServico.status = ordemServico.etapa.status;
				ordemServico.acompanhamentos.push(acompanhamento);
				ordemServico.responsavel = acompanhamento.responsavel;
				setOrdemServico({...ordemServico});
				setAcompanhamento(null);
			}
			if (ordemServico.status !== "CANCELADA" && statusConsumo.includes(acompanhamento.etapa?.status)) {
				if (ordemServico.itens?.some(osi => osi.pecas.some(osip => !["MOVIMENTADO", "CANCELADO"].includes(osip.status)))) {
					showDialog(<InformationDialog header="Informação" message={`Não é possível avançar esta ordem de serviço para ${acompanhamento.etapa.descricao} pois ainda possui peças solicitadas.`}/>);
					return;
				}
				if (ordemServico.operacao === "CAMPO" && ordemServico.itens?.some(osi => osi.pecas?.some(osip => osip.status !== "CANCELADO" && !osip.estoqueConsumoConfirmado))) {
					showDialog(<ConfirmacaoEstoquePecaCampo ordemServico={ordemServico} onConfirm={doSalvar}/>);
					return;
				}
			}
			if (acompanhamento.etapa?.status === "FATURAMENTO") {
				if (multiple) {
					showDialog(<InformationDialog header="Informação" message="Não é possível encaminhar várias ordem de serviço para faturamento ao mesmo tempo."/>);
					return;
				}
				if (ordemServico.operacao === "LABORATORIO" && !ordemServico.notaFiscal?.numero) {
					showDialog(<InformationDialog header="Informação" message="Não é possível encaminhar a ordem de serviço para faturamento sem nota fiscal de entrada."/>);
					return;
				}
			}
			setLoading(true);
			acompanhamento.atribuinte = usuario;
			doSalvar(ordemServico);
		} else {
			setMessages(messages);
			showDialog(<InformationDialog header="Informação" message="Alguns campos obrigatórios não estão preenchidos corretamente. Por favor, corrija-os."/>);
		}
	}

	function handleChange(event) {
		setAcompanhamento(prevAcompanhamento => ({...prevAcompanhamento, [event.name]: event.value}));
	}

	const isTransferencia = acompanhamento.etapa?.operacaoTransferencia?.length;

	return (
		<Panel header={multiple ? "Detalhes de Acompanhamento" : `Detalhes de Acompanhamento da ${ordemServico.numero ? "" : "Nova"} Ordem de Serviço ${ordemServico.numero || ""}`}>
			<PanelContent>
				<Dropdown
					onBlur={() => {
						messages.etapa = isEntityRequired(acompanhamento.etapa);
						setMessages({...messages});
					}}
					required
					invalid={messages.etapa}
					emptyMessage="Nenhuma etapa disponível"
					options={optionsEtapas}
					col={5}
					label="Etapa"
					name="etapa"
					value={acompanhamento.etapa}
					onChange={handleChange}
				/>
				<UsuarioAutoComplete
					invalid={messages.responsavel}
					col={7}
					label="Responsável"
					name="responsavel"
					required={required}
					value={acompanhamento.responsavel}
					onChange={handleChange}
				/>
				<If condition={isTransferencia}>
					<OrdemServicoFluxoDropdown
						label="Fluxo para Transferência"
						operacao={acompanhamento.etapa?.operacaoTransferencia}
						name="_fluxoTransferencia"
						value={acompanhamento._fluxoTransferencia}
						onChange={handleChange}
						col={6}
					/>
					<EtapaDropdown
						disabled={!acompanhamento._fluxoTransferencia?.id}
						fluxo={acompanhamento._fluxoTransferencia?.id}
						label="Etapa para Transferência"
						name="_etapaTransferencia"
						value={acompanhamento._etapaTransferencia}
						onChange={handleChange}
						options={acompanhamento._fluxoTransferencia?.etapas.filter(e => !["TRANSFERENCIA", "CANCELADA", "FECHADA"].includes(e.status)).map(e => ({label: e.descricao, value: e}))}
						col={6}
					/>
				</If>
				<InputText
					multiline
					style={{height: "13em"}}
					col={12}
					name="observacoes"
					label="Observações"
					value={acompanhamento.observacoes}
					onChange={handleChange}
				/>
			</PanelContent>
			<PanelFooter>
				<Button
					disabled={loading}
					loading={loading}
					icon="pi pi-save"
					success
					label={isLargeDevice ? "Salvar" : null}
					onClick={handleSalvar}
					autowidth={!isLargeDevice.toString()}
				/>
				<Button
					disabled={loading}
					icon="pi pi-arrow-left"
					secondary
					label={isLargeDevice ? "Voltar" : null}
					onClick={() => (onVoltar && onVoltar()) || setAcompanhamento(null)}
					autowidth={!isLargeDevice.toString()}
				/>
			</PanelFooter>
		</Panel>
	);

}
