import React, {useEffect, useMemo, useState} from 'react';
import {BlockUI} from "primereact/blockui";
import {Panel} from "primereact/panel";
import {headerTemplate} from "../../utils/templates";
import {PanelContent, PanelFooter} from "../../components/panel";
import {If} from "../../components/conditional/If";
import {RefetchIndicator} from "../../components/refetchindicator/RefetchIndicator";
import {useQueryClientSuporte} from "../../utils/query-clients/queryClients";
import {SearchButton} from "../../components/button/SearchButton";
import {usePagesAndTabs} from "../../utils/PageAndTabContext";
import {QueryParamsKeys} from "../../utils/defaultQueryParams";
import {FiltrosOrdemServicoSuporte} from "../__Commons/FiltrosOrdemServicoSuporte";
import {TabelaOrdemServico} from "./TabelaOrdemServico";
import {Button} from "../../components/button";
import {useNavigate} from "react-router-dom";

export function OrdemServicoSuporte() {

	const navigate = useNavigate();
	const {query, handleSearch} = useQueryClientSuporte();
	const [hideFilters, setHideFilters] = useState(false);
	const {pageAndTab, setScroll} = usePagesAndTabs(QueryParamsKeys.Suporte);

	useEffect(() => {
		if (pageAndTab.scroll) {
			setTimeout(() => {
				window.scrollTo(0, pageAndTab.scroll);
				setScroll(0);
			}, 50);
		}
	}, []);

	function criarOrdemServico() {
		navigate("/suporte/new");
	}

	return (
		<BlockUI blocked={query.isLoading}>
			<If condition={!hideFilters}>
				<Panel headerTemplate={() => headerTemplate("Suporte", "fa-headset")}>
					<PanelContent>
						<FiltrosOrdemServicoSuporte/>
					</PanelContent>
					<PanelFooter>
						<Button label="Novo" success icon="fas fa-plus" onClick={criarOrdemServico}/>
						<SearchButton query={query} onSearch={handleSearch}/>
					</PanelFooter>
				</Panel>
			</If>
			<TabelaOrdemServico value={useMemo(() => query.data || [], [query.data])} setHideFilters={setHideFilters}/>
			<RefetchIndicator isRefetching={query.isRefetching}/>
		</BlockUI>
	);

}
