import {useQueryParams} from "../../utils/QueryParamContext";
import {QueryParamsKeys} from "../../utils/defaultQueryParams";
import {SelectButton} from "../../components/selectbutton";
import {
	FiltroEquipamentoEspecial,
	filtrosNumeros,
	optionsFilterRestricoes,
	optionsFilterStatus,
	optionsStatusContrato,
	restricoesButtons
} from "../../service/ordemServicoService";
import {InputText} from "../../components/inputtext";
import {Dropdown} from "../../components/dropdown";
import {Calendar} from "../../components/calendar";
import {ClienteAutoComplete} from "../../components/autocomplete/ClienteAutoComplete";
import {EquipamentoAutoComplete} from "../../components/autocomplete/equipamentoAutoComplete";
import {CanceladaSelectButton} from "../../components/selectbutton/canceladaSelectButton";
import {UsuarioAutoComplete} from "../../components/autocomplete/usuarioAutoComplete";
import {VendedorAutoComplete} from "../../components/autocomplete/VendedorAutoComplete";
import {FaixaSLASelectButton} from "../../components/selectbutton/faixaSlaSelectButton";
import {OrdemServicoCorSelectButton} from "../../components/selectbutton/ordemServicoCorSelectButton";
import React from "react";
import {optionsFiltrosPecas} from "./FiltrosOrdemServicoComercial";

export function FiltrosOrdemServicoLaboratorio() {

	const {params, handleChange} = useQueryParams(QueryParamsKeys.Laboratorio);

	return (
		<>
			<SelectButton options={optionsFilterStatus} col={3} name="status" value={params.status} onChange={handleChange} label="Status"/>
			<InputText label="Número" name="numero" col={1} value={params.numero} onChange={handleChange}/>
			<Dropdown col={2} label="&nbsp;" name="modoNumero" options={filtrosNumeros.filter(fn => fn.setores.includes("LABORATORIO"))} onChange={handleChange} value={params.modoNumero}/>
			<Calendar disabled={params.equipamento?.id} label="Período de Início" col={1} name="inicio" value={params.inicio} onChange={handleChange}/>
			<Calendar disabled={params.equipamento?.id} label="&nbsp;" col={1} name="fim" value={params.fim} onChange={handleChange}/>
			<SelectButton label="Contém Peças" col={2} name="pecas" value={params.pecas} onChange={handleChange} options={optionsFiltrosPecas}/>
			<FiltroEquipamentoEspecial value={params.equipamentoEspecial} onChange={handleChange}/>
			<ClienteAutoComplete forceSelection={false} name="cliente" col={4} value={params.cliente} onChange={handleChange}/>
			<EquipamentoAutoComplete forceSelection={false} col={4} name="equipamento" value={params.equipamento} onChange={handleChange}/>
			<InputText placeholder="o código" label="Prateleira/Bancada" name="prateleira" col={2} value={params.prateleira} onChange={handleChange}/>
			<CanceladaSelectButton value={params.cancelada} onChange={handleChange}/>
			<UsuarioAutoComplete dummy dropdown col={5} label="Responsável" name="responsavel" value={params.responsavel} onChange={handleChange}/>
			<VendedorAutoComplete col={5} label="Consultor de Vendas" name="gerenteComercial" value={params.gerenteComercial} onChange={handleChange}/>
			<Dropdown col={2} label="Status Contrato" value={params.statusContrato} onChange={handleChange} name="statusContrato" options={optionsStatusContrato}/>
			<SelectButton col={6} itemTemplate={restricoesButtons} label="Restrições" multiple options={optionsFilterRestricoes.filter(r => r.setores.includes("LABORATORIO"))} name="restricoes" value={params.restricoes} onChange={handleChange}/>
			<FaixaSLASelectButton col={3} name="faixaSLA" value={params.faixaSLA} onChange={handleChange}/>
			<OrdemServicoCorSelectButton col={3} name="cores" value={params.cores} onChange={handleChange}/>
		</>
	);

}
