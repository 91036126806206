import {useAuth} from "../../context/AuthContext";
import {useQueryParams} from "../../utils/QueryParamContext";
import {QueryParamsKeys} from "../../utils/defaultQueryParams";
import React, {useEffect, useMemo} from "react";
import {
	optionsFilterRestricoes,
	optionsStatusContrato,
	optionsTiposFiltered,
	restricoesButtons
} from "../../service/ordemServicoService";
import {InputText} from "../../components/inputtext";
import {Dropdown} from "../../components/dropdown";
import {Calendar} from "../../components/calendar";
import {SelectButton} from "../../components/selectbutton";
import {ClienteAutoComplete} from "../../components/autocomplete/ClienteAutoComplete";
import {EquipamentoAutoComplete} from "../../components/autocomplete/equipamentoAutoComplete";
import {EtapaDropdown} from "../../components/autocomplete/EtapaDropdown";
import {CanceladaSelectButton} from "../../components/selectbutton/canceladaSelectButton";
import {VendedorAutoComplete} from "../../components/autocomplete/VendedorAutoComplete";
import {ResponsavelAutoComplete} from "../../components/autocomplete/responsavelAutoComplete";
import {StatusAgendamentoSelectButton} from "../../components/selectbutton/statusAgendamentoSelectButton";
import {StatusAtendimentoSelectButton} from "../../components/selectbutton/statusAtendimentoSelectButton";
import {FaixaSLASelectButton} from "../../components/selectbutton/faixaSlaSelectButton";
import {OrdemServicoCorSelectButton} from "../../components/selectbutton/ordemServicoCorSelectButton";
import {optionsFiltrosPecas} from "./FiltrosOrdemServicoComercial";

const filtrosData = [
	{label: "de Início", value: "INICIO"},
	{label: "de Previsão de Atendimento", value: "PREVISAO"},
];

export function FiltrosOrdemServicoCampo() {

	const {roles, usuario} = useAuth();
	const {params, handleChange} = useQueryParams(QueryParamsKeys.Campo);
	const optionsTipos = useMemo(() => optionsTiposFiltered(roles), [roles]);

	useEffect(() => {
		if (!roles.OSSL) {
			handleChange({name: "responsaveis", value: [usuario]});
		}
	}, [roles, usuario]);

	return (
		<>
			<InputText label="Número" name="numero" col={2} value={params.numero} onChange={handleChange}/>
			<Dropdown disabled={optionsTipos.length === 1} label="Tipo da OS" options={[{label: "Todas", value: null}, ...optionsTipos]} name="tipo" col={2} value={params.tipo} onChange={handleChange}/>
			<Calendar disabled={params.equipamento?.id} label="Período" col={1} name="inicio" value={params.inicio} onChange={handleChange}/>
			<Calendar disabled={params.equipamento?.id} label="&nbsp;" col={1} name="fim" value={params.fim} onChange={handleChange}/>
			<Dropdown col={2} label="&nbsp;" name="modoData" options={filtrosData} onChange={handleChange} value={params.modoData}/>
			<SelectButton label="Contém Peças" col={2} name="pecas" value={params.pecas} onChange={handleChange} options={optionsFiltrosPecas}/>
			<Dropdown col={2} label="Status Contrato" value={params.statusContrato} onChange={handleChange} name="statusContrato" options={optionsStatusContrato}/>
			<ClienteAutoComplete forceSelection={false} name="cliente" col={4} value={params.cliente} onChange={handleChange}/>
			<EquipamentoAutoComplete forceSelection={false} col={4} name="equipamento" value={params.equipamento} onChange={handleChange}/>
			<EtapaDropdown operacao="CAMPO" col={2} name="etapa" value={params.etapa} onChange={handleChange}/>
			<CanceladaSelectButton value={params.cancelada} onChange={handleChange}/>
			<ResponsavelAutoComplete multiple disabled={!roles.OSSL} col={4} value={params.responsaveis} name="responsaveis" onChange={handleChange} label="Responsáveis"/>
			<VendedorAutoComplete label="Consultor de Vendas" col={4} name="gerenteComercial" value={params.gerenteComercial} onChange={handleChange}/>
			<StatusAgendamentoSelectButton col={2} name="statusAgendamento" value={params.statusAgendamento} onChange={handleChange}/>
			<StatusAtendimentoSelectButton col={2} name="statusAtendimento" value={params.statusAtendimento} onChange={handleChange}/>
			<SelectButton col={6} itemTemplate={restricoesButtons} label="Restrições" multiple options={optionsFilterRestricoes.filter(r => r.setores?.includes("CAMPO"))} name="restricoes" value={params.restricoes} onChange={handleChange}/>
			<FaixaSLASelectButton col={3} name="faixaSLA" value={params.faixaSLA} onChange={handleChange}/>
			<OrdemServicoCorSelectButton col={3} name="cores" value={params.cores} onChange={handleChange}/>
		</>
	);

}
